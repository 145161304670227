
import {ref} from 'vue'
import useGoogleAuth from '../components/user/useGoogleAuth'

export default {
	name: 'about',
	setup() {
		const signIn = ref(null)
		useGoogleAuth([signIn])

		return {
			signIn,
		}
	}
}

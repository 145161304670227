<template>
	<div class="about flexc">
		<div class="card box z0">
			<br><br><br><br><br><br>
			<h1 class="center em m0">Ellis</h1>
			<span class="center">About Us</span>
			<p>
				The Ellis Project, was founded on the idea that American political discourse can and must be improved.
				Ellis is not a journalism site, nor is it a social network. Instead, Ellis provides you the tools to engage
				more productively in aspects of your civic life.
			</p>

			<p>
				Right now, the Ellis team is focused on connecting you with legislature that interests you. Democracy only
				shines when the electorate (you) is informed. At Ellis, we believe that government has a little something
				for everyone.
			</p>

			<p>
				Ellis is fiercely apolitical. Though you'll interact with legislature on both sides of the aisle, we believe
				in facts above all else. No spin here.
			</p>

			<cam-box flex="center" dir="column" p="8">
				<p align="center">Log in with your Google Account to get started.</p>
				<button ref="signIn">
					<i class="lni lni-google" style="margin-right: 12px"/>
					<small>Sign In</small>
				</button>
				<br>
				<router-link to="/privacy-policy"><small>Our Privacy Policy</small></router-link>
			</cam-box>
		</div>
	</div>
</template>

<script lang="ts">
import {ref} from 'vue'
import useGoogleAuth from '../components/user/useGoogleAuth'

export default {
	name: 'about',
	setup() {
		const signIn = ref(null)
		useGoogleAuth([signIn])

		return {
			signIn,
		}
	}
}
</script>

<style lang="stylus" scoped>
@require '~style/mixins.styl'
@require '~style/variables.styl'

.flexc {
	flexXY(center, flex-start)
}
.about {
	.card {
		width: 100%
		max-width: 50em
	}

	button {
		neu(lighten(#e6e8e9, 0%), 0.9)
		color: $c-bg
		border-radius: 20px
		small {
			font-weight: bold
			font-size: 0.85em
		}
	}
}
</style>